/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import './static/fonts/fonts.css';

let hydrateRef;

export function onRouteUpdate() {
  if (`requestIdleCallback` in window) {
    if (hydrateRef) {
      cancelIdleCallback(hydrateRef)
    }

    hydrateRef = requestIdleCallback(hydrateImages)
  } else {
    if (hydrateRef) {
      clearTimeout(hydrateRef)
    }
    hydrateRef = setTimeout(hydrateImages)
  }
}

function hydrateImages() {
  const doc = document
  const inlineWPimages = Array.from(
    doc.querySelectorAll(`[data-wp-inline-image]`)
  )

  inlineWPimages.forEach(image => {
    if (image?.dataset?.src && !image.src) {
      image.src = image.dataset.src;
      if (image.dataset.srcset) {
        image.srcset = image.dataset.srcset;
      }
    }
  });

  const links = Array.from(
    doc.querySelectorAll('main a')
  );

  links.forEach(link => {
    if (!link?.href?.startsWith('/') && !link?.href?.includes('ambaum.com')) {
      link.target = '_blank';
    }
  });
}
